class Config {
  gtagIDProd = "G-LR76BN3MW1";
  gtagIDLabs = "G-M1563Q6TC8";

  isProduction() {
    return document.location.host === "direct.lc.chat";
  }
}

const config = new Config();

export default config;
