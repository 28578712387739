import { css } from "@emotion/css";
import {
  DesignToken,
  RadiusToken,
  SpacingToken,
} from "@livechat/design-system-react-components";

export const topBar = css`
  width: 97%;
  height: 52px;
  margin-bottom: 52px;
  position: absolute;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  padding: 0 var(${SpacingToken.Spacing4});
  z-index: 100;
  border-radius: 0 0 12px 12px;
  box-shadow: 0px 10px 25px 0px rgba(0, 0, 0, 0.08);
  backdrop-filter: blur(25px);

  @media only screen and (max-width: 700px) {
    display: none;
  }

  &.light {
    background: rgba(241, 241, 241, 0.3);
    border: 1px solid rgba(189, 189, 189, 0.3);
    border-top: none;
  }

  &.dark {
    background: rgba(0, 0, 0, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.3);
    border-top: none;
  }
`;

export const title = css`
  margin: 0 var(${SpacingToken.Spacing4}) 0 var(${SpacingToken.Spacing5});
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: var(${DesignToken.ContentBasicPrimary});
  flex-shrink: 1;

  @media (min-width: 1920px) {
    font-size: 16px;
  }
`;

export const poweredByText = css`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: var(${DesignToken.ContentBasicPrimary});

  @media (min-width: 1920px) {
    font-size: 16px;
  }
`;

export const getLiveChatText = css`
  color: var(${DesignToken.ContentDefault});
  text-decoration: none;
  font-weight: 600;
  &:hover {
    text-decoration: none;
  }
`;

export const getLiveChatButton = css`
  display: flex;
  align-items: center;
  border-radius: var(${RadiusToken.Radius3});
  border: 1px solid var(${DesignToken.BorderBasicPrimary});
  padding: var(${SpacingToken.Spacing2}) var(${SpacingToken.Spacing4});
  height: 32px;
  min-width: 32px;
  background-color: var(${DesignToken.BtnSecondaryBackgroundEnabled});

  &.light,
  &.dark {
    &:hover {
      text-decoration: none;
      background-color: var(${DesignToken.BtnSecondaryBackgroundHover});
      border-color: var(${DesignToken.BorderBasicHover});
    }
  }
`;

export const logo = css`
  width: 20px;
  height: 20px;
`;

export const poweredByLink = css`
  display: flex;
  align-items: center;
  border-radius: var(${RadiusToken.Radius3});
  padding: var(${SpacingToken.Spacing2}) var(${SpacingToken.Spacing3});
  max-height: 36px;
  gap: var(${SpacingToken.Spacing2});

  &.light {
    &:hover {
      background: var(${DesignToken.SurfacePrimaryActive});
      text-decoration: none;
    }
  }
  &.dark {
    &:hover {
      background: #323236;
      text-decoration: none;
    }
  }
`;

export const rightContainer = css`
  display: flex;
  align-items: center;
  min-width: 160px;

  flex-grow: 1;
  justify-content: flex-end;
`;
