import { css } from "@emotion/css";

export const container = css`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-around;
  overflow: hidden;
`;

export const pageDescriptionWrapper = css`
  flex: 1;
  @media only screen and (max-width: 700px) {
    display: none;
  }

  &.light {
    background: #fff;
  }

  &.dark {
    background: #292929;
  }
`;

export const loaderStyles = css`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex: 0 1 auto;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
`;
