import get from "lodash/get";

export type ITheme = "light" | "dark";

interface IConfigResponse {
  __priv: {
    group: {
      "chat_window.new_theme.variant": ITheme;
      "chat_window.display_logo"?: "0" | "1";
      "chat_window.logo_path"?: string;
    };
  };
}

interface IOpenWidgetConfigResponse {
  staticConfig: {
    __priv: {
      group: {
        "chat_window.new_theme.variant": ITheme;
      };
    };
  };
  header: {
    logo: string;
  };
}

interface IDynamicConfigError {
  type: string;
  data: { region: string };
}

enum DefaultWidget {
  Livechat = "livechat",
  Openwidget = "openwidget",
}

interface IDynamicConfigResponse {
  error?: IDynamicConfigError;
  organization_id: string;
  default_widget?: DefaultWidget;
  livechat?: {
    config_version: string;
  };
}

interface IThemeAndLogo {
  theme: ITheme;
  logoEnabled: boolean;
  logoPath: string;
}

const fetchOpenWidgetConfig = (
  regionPrefix: string,
  labs: string,
  organizationId: string
): Promise<IThemeAndLogo> => {
  const openwidgetUrl = `https://api${regionPrefix}.${labs}openwidget.com/v1.0/configuration/${organizationId}`;
  return fetch(openwidgetUrl)
    .then((configResponse) => configResponse.json())
    .then((configData: IOpenWidgetConfigResponse) => {
      const theme = get(
        configData,
        ["staticConfig", "__priv", "group", "chat_window.new_theme.variant"],
        "light"
      );
      const logoPath = get(configData, ["header", "logo"], "");
      const logoEnabled = logoPath !== "";
      return { theme, logoEnabled, logoPath };
    });
};

const fetchLiveChatConfig = (
  regionPrefix: string,
  labs: string,
  organizationId: string,
  groupId: string | number,
  configVersion?: string
): Promise<IThemeAndLogo> => {
  const configUrl = `https://api${regionPrefix}.${labs}livechatinc.com/v3.5/customer/action/get_configuration?organization_id=${organizationId}&version=${configVersion}&group_id=${groupId}`;
  return fetch(configUrl)
    .then((configResponse) => configResponse.json())
    .then((configData: IConfigResponse) => {
      const theme = get(
        configData,
        ["__priv", "group", "chat_window.new_theme.variant"],
        "light"
      );
      const logoEnabled =
        get(
          configData,
          ["__priv", "group", "chat_window.display_logo"],
          "0"
        ) === "1";
      const logoPath = get(
        configData,
        ["__priv", "group", "chat_window.logo_path"],
        ""
      );
      return { theme, logoEnabled, logoPath };
    });
};

export const getConfig = (
  licenseId: number | string,
  groupId: number | string,
  isProd: boolean,
  region: string = "",
  retries: number = 0
): Promise<IThemeAndLogo> => {
  const clientId = isProd
    ? "c5e4f61e1a6c3b1521b541bc5c5a2ac5"
    : "c6e4f62e2a2dab12531235b12c5a2a6b";

  const labs = isProd ? "" : "labs.";
  const ignoreActivityForCodeInstalled = "test=1";
  const regionPrefix = region ? `-${region}` : "";
  const dynamicConfigUrl = `https://api${regionPrefix}.${labs}livechatinc.com/v3.6/customer/action/get_dynamic_configuration?license_id=${licenseId}&client_id=${clientId}&${ignoreActivityForCodeInstalled}`;

  return fetch(dynamicConfigUrl)
    .then((response) => response.json())
    .then((data: IDynamicConfigResponse) => {
      if (data.error && data.error.type === "misdirected_request") {
        if (retries < 3) {
          return getConfig(
            licenseId,
            groupId,
            isProd,
            data.error.data.region,
            retries + 1
          );
        } else {
          throw new Error("Retry limit reached after misdirected requests.");
        }
      }

      const organizationId = data.organization_id;
      const defaultWidget = data.default_widget;

      if (defaultWidget === DefaultWidget.Openwidget) {
        return fetchOpenWidgetConfig(regionPrefix, labs, organizationId);
      } else {
        const configVersion = data.livechat?.config_version;
        return fetchLiveChatConfig(
          regionPrefix,
          labs,
          organizationId,
          groupId,
          configVersion
        );
      }
    })
    .catch((error) => {
      console.error("Failed to fetch theme configuration:", error);
      throw error;
    });
};
