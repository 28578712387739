import get from "lodash/get";

interface ITranslations {
  phrases: string[];
}

interface ITranslationErrorResponse {
  error: string;
  region: string;
}

type ITranslationResponse = ITranslations | ITranslationErrorResponse;

export const getTranslations = (
  licenseId: number | string,
  groupId: number | string,
  isProd: boolean,
  region: string = ""
): Promise<string[]> => {
  const labs = isProd ? "" : "labs.";
  const url = `https://api${
    region ? `-${region}` : ""
  }.${labs}livechatinc.com/v2/chatting_page/info?id=${licenseId}&group=${groupId}`;

  return fetch(url)
    .then((response) => response.json())
    .then((data: ITranslationResponse) => {
      if (isErrorResponse(data)) {
        if (data.error === "Misdirected request" && data.region) {
          return getTranslations(licenseId, groupId, isProd, data.region);
        } else {
          throw new Error(data.error);
        }
      }

      return get(data, "phrases", []);
    })
    .catch((error) => {
      console.error("Failed to fetch translations:", error);
      throw error;
    });
};

function isErrorResponse(data: any): data is ITranslationErrorResponse {
  return "error" in data && typeof data.error === "string";
}
