import React from "react";
import { cx } from "@emotion/css";
import * as styles from "./styles";
import { ITheme } from "../../helpers/getConfig";

interface ChatWrapperProps {
  theme: ITheme;
  isLoading: boolean;
}

export const ChatWrapper: React.FC<ChatWrapperProps> = ({
  theme,
  isLoading,
}) => {
  return (
    <div className={cx(styles.baseWrapper, theme)}>
      <div className={cx(styles.blurTop, theme)}></div>
      <ChatContainer isLoading={isLoading} />
      <div className={cx(styles.blurBottom, theme)}></div>
    </div>
  );
};

interface ChatContainerProps {
  isLoading: boolean;
}

const ChatContainer: React.FC<ChatContainerProps> = ({ isLoading }) => {
  const containerClass = isLoading
    ? styles.hiddenContainer
    : styles.chatContainer;
  return <div className={containerClass} id="lc_chat_container"></div>;
};

export default ChatWrapper;
