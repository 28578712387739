import React from "react";
import "isomorphic-fetch";
import { Loader } from "@livechat/design-system-react-components";
import { cx } from "@emotion/css";

import Config from "./Config";
import { container, loaderStyles, pageDescriptionWrapper } from "./styles";
import { TopBar } from "./components/TopBar/TopBar";
import { ChatWrapper } from "./components/ChatWrapper/ChatWrapper";

import { PageDescription } from "./components/PageDescription/PageDescription";
import { NO_LICENCE } from "./consts/redirect";
import { getConfig } from "./helpers/getConfig";
import { getTranslations } from "./helpers/getTranslations";

class App extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      shouldShowLCLogo: false,
      translations: [],
      theme: "light",
      logoEnabled: false,
      logoPath: "",
    };
    this.license = this.calculateLicense();
    this.group = this.calculateGroup();
    this.isProd = Config.isProduction();
  }

  addTrackingCode = () => {
    window.__lc = window.__lc || {};
    window.__lc.license = this.license;
    window.__lc.direct_link = true;
    window.__lc.test = 1;

    const isMobile = /mobile/gi.test(navigator.userAgent);
    if (!isMobile) {
      window.__lc.custom_container =
        document.getElementById("lc_chat_container");
    }
    this.group && (window.__lc.group = this.group);

    let labs = "";
    if (!this.isProd) {
      window.__lc.hostname = "direct-labs.lc.chat";
      window.__lc.chat_absolute_url =
        document.location.protocol +
        "//source.livechatinc.com/preview/labs1/chat.html";
      labs = "labs/";
    }

    const lc = document.createElement("script");
    lc.type = "text/javascript";
    lc.async = true;
    const src = `https://cdn.livechatinc.com/${labs}tracking.js`;
    lc.src = src;
    const s = document.getElementsByTagName("script")[0];
    s.parentNode.insertBefore(lc, s);
    window.LC_API = window.LC_API || {};

    (function (w, d, s, src) {
      function handle(args) {
        return api._h ? api._h.apply(null, args) : api._q.push(args);
      }
      var api = {
        _q: [],
        _h: null,
        _v: "2.0",
        on: function () {
          handle(["on", s.call(arguments)]);
        },
        off: function () {
          handle(["off", s.call(arguments)]);
        },
        get: function () {
          if (!api._h) {
            throw new Error(
              "[LiveChatWidget] You can't use getters before load."
            );
          }
          return handle(["get", s.call(arguments)]);
        },
        call: function () {
          handle(["call", s.call(arguments)]);
        },
        init: function () {
          var t = d.createElement("script");
          t.async = true;
          t.type = "text/javascript";
          t.src = src;
          d.head.appendChild(t);
        },
      };
      !w.__lc.asyncInit && api.init();
      w.LiveChatWidget = w.LiveChatWidget || api;
    })(window, document, [].slice, src);
  };

  addGtagCode = () => {
    const gtagID = Config.isProduction()
      ? Config.gtagIDProd
      : Config.gtagIDLabs;

    const gtagScript = document.createElement("script");
    gtagScript.async = true;
    gtagScript.src = `https://www.googletagmanager.com/gtag/js?id=${gtagID}`;
    document.head.appendChild(gtagScript);

    const script = document.createElement("script");
    const inlineScript = document.createTextNode(`
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());
      gtag('config', '${gtagID}');
    `);
    script.appendChild(inlineScript);
    document.head.appendChild(script);
  };

  getValidURL = (path) => {
    if (!path.startsWith("http://") && !path.startsWith("https://")) {
      return `https://${path}`;
    } else {
      return path.replace(/^http:\/\//, "https://");
    }
  };

  componentDidMount() {
    this.addGtagCode();
    this.addTrackingCode();

    const licenseId = this.calculateLicense();
    const groupId = this.calculateGroup();
    const isProd = Config.isProduction();

    getConfig(licenseId, groupId, isProd)
      .then(({ theme, logoEnabled, logoPath }) => {
        if (logoEnabled) {
          logoPath = this.getValidURL(logoPath);
        }
        this.setState({ theme, logoEnabled, logoPath });
        document.body.classList.add(`lc-${theme}-theme`);
      })
      .catch((error) => {
        console.error("Failed to fetch config:", error);
      });

    getTranslations(licenseId, groupId, isProd)
      .then((translations) => {
        this.setState({ translations });
      })
      .catch((error) => {
        console.error("Failed to fetch translations:", error);
      });

    window.LC_API.on_after_load = () => {
      const features = window.LiveChatWidget.get("features");
      const hideTrademarkEnabled = features.hideTrademark.enabled;

      this.setState({
        isLoading: false,
        shouldShowLCLogo: !hideTrademarkEnabled,
      });
    };
  }

  callback = (ref, setStatus) => {
    this.livechat = ref;
    ref.on_after_load = function () {
      ref.open_chat_window();
      setStatus();
    };
  };

  redirectToSignUp = () => {
    window.location.replace(NO_LICENCE);
  };

  calculateLicense = () => {
    const path = window.location.pathname;
    const licenseWithGroup = path.substr(1);
    const license = licenseWithGroup.split("/")[0];
    if (license.length === 0) this.redirectToSignUp();

    return parseInt(license);
  };

  calculateGroup = () => {
    const path = window.location.pathname;
    const licenseWithGroup = path.substr(1);
    const group = licenseWithGroup.split("/")[1];
    return group || 0;
  };

  render() {
    const {
      isLoading,
      shouldShowLCLogo,
      translations,
      logoEnabled,
      logoPath,
      theme,
    } = this.state;

    return (
      <>
        {shouldShowLCLogo && <TopBar license={this.license} theme={theme} />}
        <div className={container}>
          {isLoading ? (
            <div className={loaderStyles}>
              <Loader size="large" />
            </div>
          ) : (
            <div className={cx(pageDescriptionWrapper, theme)}>
              <PageDescription
                theme={theme}
                translations={translations}
                logo={logoEnabled ? logoPath : ""}
              />
            </div>
          )}
          <ChatWrapper theme={theme} isLoading={isLoading} />
        </div>
      </>
    );
  }
}

export default App;
