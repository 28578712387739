import { css } from "@emotion/css";
import { SpacingToken } from "@livechat/design-system-react-components";

export const baseWrapper = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  box-sizing: border-box;
  height: 100vh;
  padding: 68px var(${SpacingToken.Spacing4}) var(${SpacingToken.Spacing4});
  min-width: 428px;
  overflow: hidden;
  position: relative;

  @media only screen and (max-width: 700px) {
    padding: 0;
  }

  &.light {
    background: linear-gradient(157deg, #93b6ff 1.27%, #afe3fc 94.26%);
  }

  &.dark {
    background: linear-gradient(157deg, #344462 1.27%, #3c5059 94.26%);
  }
`;

export const blurTop = css`
  width: 400px;
  height: 400px;
  right: 50px;
  transform: rotate(32.998deg);
  border-radius: 391.94px;
  filter: blur(100px);
  position: absolute;
  top: 0;

  &.light {
    background: #e4d8fe;
  }

  &.dark {
    background: #605677;
  }
`;

export const blurBottom = css`
  width: 400px;
  height: 400px;
  left: 50px;
  transform: rotate(-32.998deg);
  border-radius: 391.94px;
  filter: blur(100px);
  position: absolute;
  bottom: 0;

    &.light {
        background: #d8fef9;
    }   

    &.dark {
        background: #47726D;
`;

export const chatContainer = css`
  box-shadow: rgba(0, 0, 0, 0.25) 0px 4px 16px;
  border-radius: 10px;
  overflow: hidden;
  width: 100%;
  max-height: 100vh;

  margin: auto 0;
  transition: visibility 0.3s, width 0.3s, height 0.3s;

  @media only screen and (max-width: 700px) {
    width: 100% !important;
    height: 100% !important;
    margin: 16px !important;
  }
`;

export const hiddenContainer = css`
  visibility: hidden;
  width: 0;
  height: 0;
`;
