import { css } from "@emotion/css";
import { SpacingToken } from "@livechat/design-system-react-components";

export const container = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 52px;
  min-height: 80vh;
  @media only screen and (max-width: 700px) {
    display: none;
  }
`;

export const wrapper = css`
  padding: 0 58px;
  gap: var(${SpacingToken.Spacing2});
  display: flex;
  flex-direction: column;

  &.light {
    color: #131317;
  }

  &.dark {
    color: #e8e8e8;
  }
`;

export const title = css`
  font-size: 42px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;

  @media (min-width: 1920px) {
    font-size: 50px;
  }

  @media (min-width: 2560px) {
    font-size: 52px;
`;

export const subtitle = css`
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px;

  @media (min-width: 1920px) {
    font-size: 21px;
  }

  @media (min-width: 2560px) {
    font-size: 22px;

`;

export const logo = css`
  max-width: 74px;
`;
